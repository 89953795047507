import React, { ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const OurStory = loadable(() => import('../../components/OurStory/OurStory'))
const BannerCarousel = loadable(() => import('../../components/HomePage/BannerCarousel/BannerCarousel'))
const SEO = loadable(() => import('../../components/SEO/SEO'))

import metaImage from '../../assets/images/Story-Slider/our-story-1.webp'
import './OurStory.scss'

const OurStoryTemp = ({ pageContext: { metaData } }: any): ReactElement => {
  const bannerImages = useStaticQuery(graphql`
    query {
      ourStoryBanner {
        gallery {
          meta_data {
            alt_text
          }
        }
        optimizedImgs {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const getImageWithAltText = (): Array<any> => {
    const optimizedImages = bannerImages?.ourStoryBanner?.optimizedImgs
    const imageAltText = bannerImages?.ourStoryBanner?.gallery

    return optimizedImages.map((image: any, index: number) => ({
      image,
      altText: imageAltText[index]
    }))
  }

  return (
    <Layout>
      <SEO
        title={metaData?.title ? metaData?.title : 'Our Story - Ayala Land Premier'}
        description={metaData?.description}
        image={metaImage}
      />
      <div className="slider-fixed-container">
        <BannerCarousel text="an unparalleled legacy" images={getImageWithAltText()} hover={false} />
      </div>

      <div className="page-content">
        <OurStory />
      </div>
    </Layout>
  )
}

export default OurStoryTemp
